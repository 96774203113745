import React, {ReactNode, useMemo} from 'react'
import './App.css'
import {Breadcrumb, Button, Layout, Menu, MenuProps} from "antd";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {HomeOutlined, TeamOutlined} from '@ant-design/icons';
import {Link, useLocation} from "react-router-dom"
import {useSelector} from "react-redux"
import {getAccesses} from "redux/selectors/environment"
import useDispatch from "hooks/useDispatch"
import {getCustomers} from "redux/selectors/definition"

const headerMenu: MenuProps['items'] = [{
  key: 'full-admin',
  label: `Full admin`,
}];


//   [UserOutlined, LaptopOutlined, NotificationOutlined].map(
//   (icon, index) => {
//     const key = String(index + 1);
//
//     return {
//       key: `sub${key}`,
//       icon: React.createElement(icon),
//       label: `subnav ${key}`,
//
//       children: new Array(4).fill(null).map((_, j) => {
//         const subKey = (index * 4) + j + 1;
//         return {
//           key: subKey,
//           label: `option${subKey}`,
//         };
//       }),
//     };
//   },
// );

interface Props {
  content: ReactNode
}

const AppLayout: React.FC<Props> = ({content}) => {
  const accesses = useSelector(getAccesses)
  const location = useLocation()
  const dispatch = useDispatch()

  const active = (useSelector(getCustomers) || [])
    .filter((customer) => !customer.deactivationDate)
    .sort((a, b) => a.name.localeCompare(b.name))
  const inactive = (useSelector(getCustomers) || [])
    .filter((customer) => Boolean(customer.deactivationDate))
    .sort((a, b) => a.name.localeCompare(b.name))


  const siderMenu: MenuProps['items'] = useMemo(() => {
    const res: MenuProps['items'] = []
    res.push({
      key: 'full-admin',
      label: <Link to={"/app/full-admin"}>Home</Link>,
      icon: <HomeOutlined/>,
    })
    res.push({
      key: "customers",
      label: "Customers",
      icon: <TeamOutlined/>,
      children: active.concat(inactive).map((customer) => ({
        key: customer.name,
        label: customer.deactivationDate ?
          <Link to={`/app/full-admin/customer?id=${customer.id}`} style={{
            fontStyle: "italic",
            color: "gray"
          }}>{customer.name}</Link> :
          <Link to={`/app/full-admin/customer?id=${customer.id}`}>{customer.name}</Link>,
      })),
    })

    return res
  }, [active, inactive])

  const breadCrumbsItems = useMemo(() => {
    const res = [{
      title: 'Home',
    }]
    if (location.pathname.includes("full-admin")) {
      res.push({title: 'Admin'})
    }
    return res
  }, [location.pathname])

  return <Layout>
    {/*<Header style={{display: 'flex', alignItems: 'center'}}>*/}
    {/*  <Menu*/}
    {/*    // theme="dark"*/}
    {/*    mode="horizontal" items={items1}/>*/}
    {/*</Header>*/}
    <Layout style={{minHeight: "100vh"}}>
      <Sider width={200}>
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{height: '100%', borderRight: 0}}
          items={siderMenu}
        />
        <Button onClick={() => dispatch.environment.logout({})}>Sign out</Button>
      </Sider>
      <Layout style={{padding: '0 24px 24px'}}>
        <Breadcrumb style={{margin: '16px 0'}} items={breadCrumbsItems}>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {content}
        </Content>
      </Layout>
    </Layout>
  </Layout>
}

export default AppLayout

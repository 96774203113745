import Api from "services/api"
import {CustomerDtoDetail} from "models/Customer"

export const listCustomers = async (): Promise<CustomerDtoDetail[]> =>
  Api.get<void, CustomerDtoDetail[]>(`/customer?deactivationDateLower=1970-01-01`)

export const updateCustomers = async (customerId: string, payload: CustomerDtoDetail): Promise<CustomerDtoDetail> =>
  Api.put<void, CustomerDtoDetail>(`/customer/${customerId}`, payload)

export const deactivateCustomer = async (customerId: string): Promise<CustomerDtoDetail> =>
  Api.put<void, CustomerDtoDetail>(`/customer/${customerId}/deactivate`)

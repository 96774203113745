import React, {FC} from "react"
import {Button, Col, Divider, Empty, Popconfirm, Row, Tag} from "antd"
import SequenceInfo from "components/SequenceInfo"
import {StyledCard} from "components/StyledCard"
import CustomerEdition from "components/CustomerEdition"
import AnalystList from "components/AnalystList"
import ServiceAccountList from "components/ServiceAccountList"
import {CustomerDtoDetail} from "models/Customer"
import ServiceAccountCreator from "components/ServiceAccountCreator"
import {useDispatch} from "hooks"

interface Props {
  customer?: CustomerDtoDetail,
}

const CustomerInfo: FC<Props> = ({customer}) => {
  const dispatch = useDispatch()

  if (!customer) {
    return <Empty/>
  }
  const deactivatedRender = customer.deactivationDate ? <Tag style={{marginLeft: 10}} color={"red"}>Deactivated</Tag> : <></>
  return <StyledCard title={<div>Customer - {customer.name} {deactivatedRender}</div>}
                     extra={<div>
                       <Popconfirm
                         title="Customer deactivation"
                         description="Do you really want to deactive this customer ?"
                         onConfirm={() => dispatch.definition.deactivateCustomer(customer)}
                         okText="Yes"
                         cancelText="No"
                       >
                         <Button disabled={Boolean(customer.deactivationDate)} danger type={"primary"}>Deactivate</Button></Popconfirm>
                     </div>}
  >
    <Row gutter={{xs: 8, sm: 16, md: 24}}>
      <Col className="gutter-row" span={8}>
        <StyledCard title={"Settings"}>
          <CustomerEdition customer={customer}/>
        </StyledCard>
      </Col>
      <Col className="gutter-row" span={8}>
        <StyledCard title={"Analysts"}>
          <AnalystList customer={customer}/>
        </StyledCard>
      </Col>
      <Col className="gutter-row" span={8}>
        <StyledCard title={"Service Accounts"}>
          <ServiceAccountCreator customerAbbreviation={customer.abbreviation}/>
          <Divider/>
          <ServiceAccountList customer={customer}/>
        </StyledCard>
      </Col>
    </Row>

    {customer.sequences.map((elem, i) => <SequenceInfo sequence={elem} key={i}/>)}
  </StyledCard>
}


export default CustomerInfo

